import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";

import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";

import Vant from 'vant';
import 'vant/lib/index.css';
 
import "@/styles/index.scss"
import "@/assets/Fonts/font.css"

const app = createApp(App);

app.use(createPinia())
	.use(Vant)
	.use(router)
	.use(VueViewer, {
		defaultOptions: {
			navbar: false,
			title: false,
			toolbar: true,
			tooltip: false,
			movable: false,
			zoomable: false,
			rotatable: false,
			scalable: false,
			fullscreen: false,
			keyboard: false,
		},
	})
	.mount("#app");
