// @ts-check
import { defineStore } from "pinia";
export const useUserStore = defineStore({
	id: "user",
	state: () => ({
		wallet: ""
	}),
	actions: {
		/**
		 * 登录
		 * @param {Object} user 用户对象
		 * @return {void}
		 */
		login(wallet: string): void{
			this.wallet = wallet
			localStorage.setItem("wallet", wallet)
		},
		/**
		 * 登出
		 * @return {void}
		 */
		logout(): void{
			this.wallet = ""
			// setUserInfo({})
			localStorage.clear()
		}
	},
	getters: {
		getWallet: (state) => state.wallet,
	}
});